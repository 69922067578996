footer.content-info {
    background-color: $footer-bg-color;

    padding: 2rem 0;

    .footer-nav {
        color: $white;

        text-align: center;

        li {
            border-right: 1px solid #FFFFFF;

            font-family: $footer-nav-li-font;
            font-size: 0.75rem;

            margin-left: 0;
            margin-right: 0;

            padding-left: 0.5rem;
            padding-right: 0.5rem;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border-right: none;

                padding-right: 0;
            }

            a {
                color: $white;

                margin-left: 0;
                margin-right: 0;

                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
