body.home {
    .carousel-with-overlay {
        .carousel-cell {
            position: relative;

            height: 300px;

            @include media-breakpoint-up('md') {
                height: 350px;
            }

            @include media-breakpoint-up('lg') {
                height: 65vh;
            }

            .overlay {
                background-color: $default-overlay-color;

                width: 100%;

                @include media-breakpoint-up('md') {
                    position: absolute;

                    top: 50%;
                    left: 50%;

                    width: 550px;

                    transform: translate3d(-50%, -50%, 0);
                }

                @include media-breakpoint-up('lg') {
                    width: 700px;
                }

                .overlay-content {
                    padding: 4rem 2rem;

                    color: $white;

                    text-align: center;

                    h1 {
                        color: $overlay-heading-color;

                        font-family: $overlay-h1-font;
                        font-size: 1.35rem;
                        font-weight: bold;

                        letter-spacing: 2px;

                        text-transform: uppercase;
                    }

                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        font-family: $overlay-h1-font;
                    }

                    hr {
                        border-top-color: $overlay-hr-color;
                        border-top-width: 2px;

                        width: 90px;
                    }

                    p {
                        font-family: $overlay-p-font;
                        font-size: 0.85rem;
                        font-weight: normal;

                        letter-spacing: 1px;
                    }
                }
            }
        }

        .flickity-prev-next-button {
            background-color: transparent;
        }

        .flickity-prev-next-button.next {
            @include media-breakpoint-up('md') {
                right: 90px;
            }
        }

        .flickity-prev-next-button.previous {
            @include media-breakpoint-up('md') {
                left: 90px;
            }
        }

        .flickity-prev-next-button .arrow {
            fill: #fff;

            opacity: 0.7;
        }
    }

    #hero-section {
        clip-path: url("#curve-clip-shape");
        clip-path: polygon(
            0 0,
            100% 0,
            100% 91.67%,
            97.5% 92.56%,
            95% 93.30%,
            92.5% 94.04%,
            90% 94.64%,
            87.5% 95.09%,
            85% 95.68%,
            82.5% 96.28%,
            80% 96.73%,
            77.5% 97.17%,
            75% 97.62%,
            72.5% 98.07%,
            70% 98.51%,
            67.5% 98.76%,
            65% 99.11%,
            62.5% 99.26%,
            60% 99.55%,
            57.5% 99.70%,
            55% 99.85%,
            52.5% 100%,
            50% 100%,
            47.5% 100%,
            45% 99.85%,
            42.5% 99.70%,
            40% 99.55%,
            37.5% 99.26%,
            35% 99.11%,
            32.5% 98.76%,
            30% 98.51%,
            27.5% 98.07%,
            25% 97.62%,
            22.5% 97.17%,
            20% 96.73%,
            17.5% 96.28%,
            15% 95.68%,
            12.5% 95.09%,
            10% 94.64%,
            7.5% 94.04%,
            5% 93.30%,
            2.5% 92.56%,
            0 91.67%,
            0 0
        );

        .flickity-prev-next-button {
            display: none;
        }

        .flickity-page-dots {
            bottom: 10%;

            .dot {
                background: #ffffff;

                opacity: 1;

                &.is-selected {
                    background: #45546f;

                    opacity: 1;
                }
            }
        }
    }

    #content-section {
        text-align: center;

        padding-top: 2rem;

        &:before {
            content: '';

            background-image: url('../images/home-body-content-bg.png');
            background-position: 0px 0px;
            background-repeat: no-repeat;

            position: absolute;

            top: -50px;
            left: 0;
            right: 0;
            bottom: 0;
        }

        h1 {
            text-align: center;
        }

        h2 {
            text-align: center;
        }

        hr {
            margin-left: auto;
        }
    }
}
