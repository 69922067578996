body {
    color: $body-color;

    font-family: $body-font;
}

h1,
h2,
h3,
h4
h5,
h6 {
    color: $heading-color;
}

.wrap > .content > .main {
    padding: 0;
}

.vertical-center-wrap {
    display: table;

    width: 100%;
    min-height: 100%;
    height: 100%;

    overflow: hidden;
}

.vertical-center-content {
    display: table-cell;
    position: relative;
    vertical-align: middle;
}

.carousel.full-width-carousel {
    .carousel-cell {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        width: 100%;
        min-height: 300px;
    }
}

.content-section {
    font-size: 0.85rem;

    line-height: 1.5;

    position: relative;

    padding-top: 4rem;
    padding-bottom: 4rem;

    h1 {
        font-size: 2.15rem;

        letter-spacing: 2px;

        text-align: left;
        text-transform: uppercase;
    }

    h2 {
        color: #878685;

        font-size: 1.5rem;
        font-weight: normal;

        text-align: left;
    }

    hr {
        border-top-color: #cacacc;
        border-top-width: 2px;

        width: 90px;

        text-align: left;

        margin-left: 0;
        margin-right: auto;
    }

    .read-more-btn {
        margin-top: 2rem;

        text-transform: uppercase;
    }
}
