body.page {
    .page-header {
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;

        padding-top: 5rem;
        padding-bottom: 5rem;

        clip-path: url("#curve-clip-shape");
        clip-path: polygon(
            0 0,
            100% 0,
            100% 91.67%,
            97.5% 92.56%,
            95% 93.30%,
            92.5% 94.04%,
            90% 94.64%,
            87.5% 95.09%,
            85% 95.68%,
            82.5% 96.28%,
            80% 96.73%,
            77.5% 97.17%,
            75% 97.62%,
            72.5% 98.07%,
            70% 98.51%,
            67.5% 98.76%,
            65% 99.11%,
            62.5% 99.26%,
            60% 99.55%,
            57.5% 99.70%,
            55% 99.85%,
            52.5% 100%,
            50% 100%,
            47.5% 100%,
            45% 99.85%,
            42.5% 99.70%,
            40% 99.55%,
            37.5% 99.26%,
            35% 99.11%,
            32.5% 98.76%,
            30% 98.51%,
            27.5% 98.07%,
            25% 97.62%,
            22.5% 97.17%,
            20% 96.73%,
            17.5% 96.28%,
            15% 95.68%,
            12.5% 95.09%,
            10% 94.64%,
            7.5% 94.04%,
            5% 93.30%,
            2.5% 92.56%,
            0 91.67%,
            0 0
        );

        h1 {
            color: $white;

            font-family: $sans-serif;
            font-size: 1.75rem;
            font-weight: bold;

            letter-spacing: 2px;

            text-align: center;
            text-transform: uppercase;
        }
    }

    #content-section {
        &:before {
            content: '';

            background-image: url('../images/body-content-bg.png');
            background-position: 0px 0px;
            background-repeat: no-repeat;

            position: absolute;

            top: -50px;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}
