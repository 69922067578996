// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Colors
$white:                 #FFFFFF;
$abbey:                 #474749;
$atoll:                 #0D5381;
$jumbo:                 #747475;
$viking:                #6BCBD5;
$brand-primary:         $atoll;
$brand-info:            #5bc0de;

$body-color:            $jumbo;
$heading-color:         $abbey;
$default-overlay-color: rgba(13, 83, 129, 0.85);
$overlay-heading-color: $white;
$overlay-hr-color:      $overlay-heading-color;
$footer-bg-color:       $atoll;


// Fonts
$myriad-pro:            "Myriad Pro", "Lucida Grande", "Lucida Sans Unicode", "Gill Sans", "Gill Sans MT", Calibri, Arial, sans-serif;
$droid-sans:            'Droid Sans', sans-serif;
$sans-serif:            "Helvetica Neue", HelveticaNeue, "TeX Gyre Heros", TeXGyreHeros, FreeSans, "Nimbus Sans L", "Liberation Sans", Arimo, Helvetica, Arial, sans-serif;

$body-font:             $droid-sans;
$overlay-h1-font:       $sans-serif;
$overlay-p-font:        $myriad-pro;
$footer-nav-li-font:    $droid-sans;
