header.banner {
    border-bottom: 2px solid #0d5381;

    box-shadow: 0px 0px 6px #aaaaaa;

    .logo {
        height: 40px;

        @include media-breakpoint-up('lg') {
            height: 70px;
        }
    }

    .nav {
        li {
            font-family: $sans-serif;
            font-size: 0.85rem;

            text-transform: uppercase;

            margin-left: 0.5rem;

            &.show {
                a {
                    background-color: #72953d;

                    border-radius: 4px;

                    color: #ffffff;
                }
            }

            a {
                color: #555555;

                padding: 0.5rem 0.75rem;

                &:hover {
                    background-color: #72953d;

                    border-radius: 4px;

                    color: #ffffff;
                }

                &:hover,
                &:focus,
                &:visited {
                    outline: 0;
                    text-decoration: none;
                }

                .caret {
                    display: none;
                }
            }
        }

        .dropdown {
            li {
                a {
                    padding: 0;
                    padding-left: 0.5rem;

                    background-color: transparent;

                    border-radius: 0;

                    color: #555555;

                    padding: 0;
                    padding-left: 0.5rem;

                    &:hover {
                        color: #0d5381;

                        font-weight: bold;
                    }
                }
            }

            .dropdown-menu {
                background-color: rgba(#fff, 0.9);

                border: none;

                border-radius: 0;

                box-shadow: 0px 0px 6px #aaaaaa;

                padding-top: 2.5rem;

                top: 110%;

                li {
                    margin-bottom: 0.35rem;
                }
            }
        }

        .dropdown-toggle:after {
            display: none;
        }
    }
}
